import React, { useState } from "react";

import SegmentHeader from "./segmentHeader";
import SegmentBody from "./segmentBody";
import SegmentFooter from "./segmentFooter";

function SegmentCard({
  onChangeSegmentType,
  segmentName,
  type,
  variables,
  onAddNewData,
  onDeleteSegment,
  onChangeAudio,
  getAssetsOptions,
  onDeleteVariable,
  segmentIndex,
}) {
  const [dataToAdd, setDataToAdd] = useState("");
  const [collapsed, setCollapsed] = useState(true);
  const initialSelectedAssets = variables.filter(({ name }) => name !== null);
  const [selectedAssets, setSelectedAssets] = useState(initialSelectedAssets);
  const onChange = value => {
    setCollapsed(false);
    onChangeSegmentType(segmentName, value);
  };
  const onCollapse = event => {
    event.stopPropagation();
    if (!type) return;
    setCollapsed(!collapsed);
  };
  const variablesValues = variables.map(({ value }) => value);
  return (
    <div className={`card ${collapsed && "card-collapsed"}`}>
      <div className="card-status bg-red" style={{ zIndex: 3, position: "sticky", top: 70 }} />
      <SegmentHeader
        type={type}
        segmentName={segmentName}
        variables={variables}
        onCollapse={onCollapse}
        onChangeSegmentType={onChange}
        onDelete={onDeleteSegment}
        collapsed={collapsed}
      />
      {type && !collapsed && (
        <>
          <SegmentBody
            type={type}
            segmentIndex={segmentIndex}
            segmentName={segmentName}
            variables={variables}
            getAssetsOptions={getAssetsOptions}
            selectedAssets={selectedAssets}
            setSelectedAssets={setSelectedAssets}
            onChangeAudio={onChangeAudio}
            onDeleteVariable={onDeleteVariable}
          />
          <SegmentFooter
            onAddNewData={onAddNewData}
            variables={variablesValues}
            type={type}
            dataToAdd={dataToAdd}
            setDataToAdd={setDataToAdd}
          />
        </>
      )}
    </div>
  );
}

export default SegmentCard;
