import React, { Component, useMemo } from "react";
import { connect } from "react-redux";
import { mapDispatchToProps } from "../../utils";
import { Map, Trash } from "react-feather";

import Table from "../table";
import { mappingSegment } from "./segmentType";
import AssetSelector from "./assetSelector/";
import { AudioControls } from "../player/audioControls";
import { formatGEOCoordinates } from "../../utils";
import { useFuseSearch } from "../../hooks/useFuseSearch";

class PodcastSegmentLabel extends Component {
  render() {
    const allPodcasts = this.props.podcasts.all || [];
    const podcast = allPodcasts.find(({ id }) => id === this.props.id);
    if (!podcast) return this.props.id;
    return (
      <>
        {podcast.podcastTitle}
        <div className="small text-monospace text-secondary">{podcast.organisationName}</div>
      </>
    );
  }
}
const PodcastSegmentLabelConnected = connect(({ podcasts }) => ({ podcasts }), mapDispatchToProps)(PodcastSegmentLabel);

const buildValue = (type, variable, options) => {
  if (type === "poi" && typeof variable.value === "object") {
    const longitude = formatGEOCoordinates(variable.value.lon);
    const latitude = formatGEOCoordinates(variable.value.lat);
    const radius = variable.value.rad;
    return (
      <>
        {variable.value.label || variable.display}
        <a
          href={`https://www.google.fr/maps/place/${latitude},${longitude}`}
          className="d-flex justify-content-center align-items-center small text-monospace text-secondary"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Map className="w-4 mr-2" />
          {`${latitude}, ${longitude} - ${radius}`}
        </a>
      </>
    );
  } else if (type === "podcast") {
    return <PodcastSegmentLabelConnected id={variable.value} />;
  } else if (type === "random") {
    return `Random ${variable.value}`;
  } else {
    const option = options && options.find(option => option.value === variable.value);
    return (option && option.label) || variable.value;
  }
};

const getDataTable = ({
  selectedAssets,
  setSelectedAssets,
  assetsOptions,
  segmentName,
  onChangeAudio,
  onDeleteTableRow,
  segmentIndex,
  type,
  variables,
}) => {
  const options = mappingSegment[type] && mappingSegment[type].options;
  return variables.map((variable, index) => {
    const value = buildValue(type, variable, options);
    const selectedAsset = selectedAssets.length > 0 && selectedAssets.find(asset => asset.value === variable.value);
    let assetSelectComponent = (
      <AssetSelector
        type={type}
        variable={variable}
        assetsOptions={assetsOptions}
        selectedAssets={selectedAssets}
        setSelectedAssets={setSelectedAssets}
        onChange={onChangeAudio(segmentName, variable.value)}
      />
    );

    const actionButtons = (
      <div className="d-flex justify-content-center align-items-center">
        <AudioControls
          assetName={selectedAsset ? selectedAsset.name : variable.name}
          currentRowIndex={index}
          currentSegmentIndex={segmentIndex}
        />
        {(index !== 0 || variable.type === "random") && (
          <button
            className="btn btn-sm btn-link d-flex justify-content-center align-items-center text-secondary"
            onClick={onDeleteTableRow(segmentName, variable.value)}
          >
            <Trash />
          </button>
        )}
      </div>
    );

    return [variable.display || variable.value, value, assetSelectComponent, actionButtons];
  });
};

function SegmentBody({
  selectedAssets,
  setSelectedAssets,
  getAssetsOptions,
  segmentName,
  onChangeAudio,
  onDeleteVariable,
  segmentIndex,
  type,
  variables,
}) {
  const onDeleteTableRow = (name, value) => event => {
    onDeleteVariable(name, value);
  };
  const assetsOptions = useMemo(() => getAssetsOptions(), [getAssetsOptions]);

  const tableData = getDataTable({
    selectedAssets,
    setSelectedAssets,
    assetsOptions,
    type,
    onDeleteTableRow,
    segmentName,
    onChangeAudio,
    segmentIndex,
    variables,
  });
  return (
    <div className="card-body" style={{ padding: 0 }}>
      <Table headers={["Variables", { title: "Audio", props: { style: { minWidth: "500px" } } }, ""]} data={tableData} />
    </div>
  );
}

export default SegmentBody;
