import React from "react";
import { ChevronUp, ChevronDown, Trash, AlertTriangle, Info } from "react-feather";

import { Select } from "../forms";
import Tooltip from "../display/tooltip";

const defaultSegmentType = [
  { label: "Hour", value: "hour" },
  { label: "Day of week", value: "dayOfWeek" },
  { label: "Day", value: "day" },
  { label: "Device type", value: "deviceType" },
  { label: "Device OS", value: "deviceOs" },
  { label: "City", value: "city", disabled: true },
  { label: "Country", value: "country" },
  { label: "Zipcode", value: "zipcode", disabled: true },
  { label: "Default", value: "default" },
  { label: "Point of interest", value: "poi" },
  { label: "Weather", value: "weather" },
  { label: "Podcast", value: "podcast" },
  { label: "Random", value: "random" },
];

const getSegmentTypeOptions = type => {
  const optionType = defaultSegmentType.filter(option => option.value === type);
  return [...defaultSegmentType, ...optionType];
};

function VariableStatusOverview({ variables, type }) {
  const totalVariablesToCheck = variables.filter(variable => !variable.isComplete);
  const incomplete = totalVariablesToCheck.length > 0;
  const defaultNotSet = totalVariablesToCheck.some(
    variable => variable.value === "default" && (variable.name === "" || variable.name === null),
  );
  const checkedVariables = totalVariablesToCheck.filter(variable => variable.name !== null && variable.name !== "");
  const plural = totalVariablesToCheck.length !== 1 ? "s" : "";
  const tooltipTextPOIWithDefault = "POI segments can be saved incomplete but the default variable must still be set.";
  const tooltipTextPOIWithoutDefault =
    "Please set an audio for the default variable. POI segments can be saved incomplete but the default variable must still be set.";
  return (
    <div className="px-4 d-flex align-items-center">
      {type === "poi" && incomplete && (
        <>
          <div className="pr-1 py-2 text-primary">{`Checking progress: ${checkedVariables.length} / ${totalVariablesToCheck.length}`}</div>
          {!defaultNotSet && (
            <div className="mt-1 mr-4 ml-1 ">
              <Tooltip text={tooltipTextPOIWithDefault}>
                <Info className="w-4 text-info" />
              </Tooltip>
            </div>
          )}
          {defaultNotSet && (
            <div className="mt-1 mr-4 ml-1 ">
              <Tooltip text={tooltipTextPOIWithoutDefault}>
                <AlertTriangle className="w-4 text-warning" />
              </Tooltip>
            </div>
          )}
        </>
      )}
      <div className="py-2 text-secondary">{`${variables.length} variable${plural} in total`}</div>
    </div>
  );
}

export default function SegmentHeader({ type, segmentName, variables, onCollapse, onChangeSegmentType, onDelete, collapsed }) {
  const onDeleteSegment = event => {
    event.stopPropagation();
    onDelete(segmentName);
  };
  return (
    <div className="bg-white card-header" onClick={onCollapse} style={{ cursor: "pointer", position: "sticky", top: 70, zIndex: 2 }}>
      <h3 className="card-title">{segmentName}</h3>
      <VariableStatusOverview type={type} variables={variables} />
      <div className="card-options">
        <div style={{ width: "200px" }}>
          <Select options={getSegmentTypeOptions(type)} onChange={onChangeSegmentType} notInForm value={type} />
        </div>
        <button
          type="button"
          className="btn btn-link btn-sm d-flex justify-content-center align-items-center text-secondary"
          onClick={onDeleteSegment}
        >
          <Trash />
        </button>
        <button type="button" className="mt-2 mr-1 btn btn-link btn-sm d-flex justify-content-center align-items-center text-secondary">
          {!collapsed ? <ChevronUp /> : <ChevronDown />}
        </button>
      </div>
    </div>
  );
}
