import React, { useState, useRef } from "react";

const Tooltip = ({ children, text, position = "right" }) => {
  const [isVisible, setIsVisible] = useState(false);
  const tooltipRef = useRef(null);

  return (
    <div className="d-inline-block position-relative" onMouseEnter={() => setIsVisible(true)} onMouseLeave={() => setIsVisible(false)}>
      {children}
      <div
        ref={tooltipRef}
        className={`tooltip bs-tooltip-${position} fade ${isVisible ? "show" : ""}`}
        role="tooltip"
        style={{
          left: "100%",
          top: "50%",
          transform: "translateY(-50%)",
        }}
      >
        <div className="tooltip-arrow" />
        <div className="bg-secondary px-2 py-1 text-white" style={{ width: "450px", borderRadius: "3px" }}>
          {text}
        </div>
      </div>
    </div>
  );
};

export default Tooltip;
